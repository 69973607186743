import * as React from 'react'
import { graphql } from 'gatsby'
import LinkTile from '../components/tiles/linkTile.js'
import Layout from '../components/layout'

const Blog = ({ data }) => {
    return (
      <Layout data={data.wpPage}>
        <div className="main">
          <div className="container mx-auto py-20">
            <div className="w-2/3 grid grid-cols-1 xl:grid-cols-2 gap-10 mx-auto">
            {
                data.allWpPost.nodes.map(node => (
                    <LinkTile resourceData={node} />
                ))
            }
            </div>
          </div>
        </div>
      </Layout>
    )
}

export const query = graphql`
query MyQuery {
    allWpPost(sort: {fields: date, order: DESC}) {
      nodes {
        id
        guid
        title
        uri
        slug
        content
        featuredImage {
          node {
            gatsbyImage(fit: COVER, width: 1000, height: 500)
            localFile {
              childImageSharp{
                gatsbyImageData(width:200)
              }
            }
          }
        }
      }
    }
    wpPage(slug: {eq: "blog"}) {
      title
      content
      isFrontPage
      nodeType
      featuredImage {
        node {
          gatsbyImage(width: 1000)
          localFile {
            childImageSharp{
              gatsbyImageData(width:200)
            }
          }
        }
      }
      seo {
        metaDesc
        opengraphUrl
        opengraphType
        opengraphTitle
        opengraphSiteName
        title
      }
      defaults {
        subtitle
        sectionPack {
          fieldGroupName
          sectionName
          sectionFields {
            content
            fieldGroupName
            image {
              gatsbyImage(width: 1000)
            }
          }
        }
      }
    }
  }
`
export default Blog