import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const DisplayTile = ({ resourceData }) => {
  const image = getImage(resourceData.featuredImage.node.gatsbyImage)
  return (
    <div className="mx-auto py-[0.5rem] ">
        <Link to={resourceData.slug}>
            <div className="tile relative drop-shadow-xl rounded-[30px] hover:drop-shadow-2xl" >
                <div className="relative hover:opacity-30">
                    <GatsbyImage image={image} layout="fullWidth" />
                </div>
                <div className="tile__body relative py-[1rem] bg-white">
                    <h5 className="text-center text-[1.5rem] font-serif">{resourceData.title}</h5>
                </div>
            </div>
        </Link>
    </div>
  )
}

export default DisplayTile